// Open Sans использует rgs
@import './fonts.css';

$font-family-sans-serif: -apple-system, BlinkMacSystemFont, 'PT Sans',
  'Segoe UI', Roboto, 'PT Sans', 'Helvetica Neue', Arial, 'Noto Sans',
  sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';

.font-weight-semibold {
  font-weight: 600 !important;
}

//Все стили bootstrap
@import '~bootstrap/scss/bootstrap.scss';
